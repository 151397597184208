/**
 * Sitewide bootstrap js
 */

// axios http module
import axios from "axios";

// BS5 Collapse for the nav bar
import Collapse from "bootstrap/js/dist/collapse";

// BS5 Dropdown for the main menu
import Dropdown from "bootstrap/js/dist/dropdown";

// BS5 Carousel for the main guidance page
import Carousel from "bootstrap/js/dist/carousel";

// BS5 Modal for the login box
import Modal from "bootstrap/js/dist/modal";

// import css
import "@/../scss/myforest.scss";

// get cookie dom refs
const cookieButton = document.getElementById("btn_cookie_continue");
const cookieBar = document.getElementById("cookie_bar");

// if the dom refs are found ok
if (cookieButton && cookieBar) {

	// add dismiss button click listener
	cookieButton.addEventListener("click", () => {

		// hide the cookie bar
		cookieBar.style.display = "none";

		// post the cookie option to the backend
		axios.post("/cookie-options", {
			cookietype: "dismisscookie",
			status: "yes"
		});
	});
}

// init BS5 login modals
let loggedInModal = null;
let loginModal = null;
const loggedInModalEl = document.getElementById("modal_logged_in");
const loginModalEl = document.getElementById("modal_login");
if (loggedInModalEl) loggedInModal = new Modal(loggedInModalEl);
if (loginModalEl) loginModal = new Modal(loginModalEl);

// get login dom refs
const agentLinks = document.getElementsByClassName("agent_link");
const loginButton = document.getElementById("login_button");
const loginEmail = document.getElementById("login_email");
const loginFail = document.getElementById("login_fail");
const loginLink = document.getElementById("login_link");
const loginPassword = document.getElementById("login_password");
const ownerLinks = document.getElementsByClassName("owner_link");
const profileDropdown = document.getElementById("profile_dropdown");
const registerLink = document.getElementById("register_link");

// if the dom refs are found ok
if (agentLinks && loginButton && loginEmail && loginFail && loginLink && loggedInModal && loginModal && loginPassword && ownerLinks && profileDropdown && registerLink) {

	// email input enter key
	loginEmail.addEventListener("keypress", e => {
		if (e.key === "Enter") login();
	});

	// password input enter key
	loginPassword.addEventListener("keypress", e => {
		if (e.key === "Enter") login();
	});

	// login button click listener
	loginButton.addEventListener("click", () => login());
}

// try a login
function login () {

	// post the login to the backend
	axios.post("/user/login/mf", {
		email: loginEmail.value,
		password: loginPassword.value
	}).then(res => {

		// hide any previous errors
		loginFail.classList.add("d-none");

		// agent login success
		if (res?.data?.result == "logged_in_agent") {
			loginModal.hide();
			loggedInModal.show();
			loginLink.classList.add("d-none");
			registerLink.classList.add("d-none");
			profileDropdown.classList.remove("d-none");
			for (let link of agentLinks) link.classList.remove("d-none");
			window.open("/agent-overview", "_blank");
		}

		// owner login success
		if (res?.data?.result == "logged_in_owner") {
			loginModal.hide();
			loggedInModal.show();
			loginLink.classList.add("d-none");
			registerLink.classList.add("d-none");
			profileDropdown.classList.remove("d-none");
			for (let link of ownerLinks) link.classList.remove("d-none");
			window.open("/app", "_blank");
		}

		// login failed
		if (res?.data?.result == "bad_credentials") {
			loginFail.innerHTML = "Email or password not recognised. Please try again.";
			loginFail.classList.remove("d-none");
		}

		// registration not confirmed
		if (res?.data?.result == "account_not_confirmed") {
			loginFail.innerHTML = "You have not confirmed your email. Please confirm before logging in. <a href='resend-confirmation'>Click here for more details.</a>";
			loginFail.classList.remove("d-none");
		}
	});
}
